<template>
  <div style="min-height: 100px;">
    <local-upload
      ref="upload"
      :pickfiles="'pickfiles-files-'+uploadUUID"
      :container="'container-files-'+uploadUUID"
      :documentDirId="-1"
      :notShowDefaultList="true"
      :autoStart="true"
      :multiSelection="false"
      @onOneUploadComplete="onOneUploadComplete"
      @onBeforeUpload="onBeforeUpload"
      @onError="onError">
      <div slot="uploader">
        <div :id="'container-files-'+uploadUUID">
          <a-button type="dashed"
                    class="m-r-12 cursor-pointer"
                    icon="upload"
                    :loading="uploadStatus"
                    :id="'pickfiles-files-'+uploadUUID">
            <span v-if="importStep === 'wait'">选择导入</span>
            <span v-if="importStep === 'upload'">上传中</span>
            <span v-if="importStep === 'submit'">处理中</span>
          </a-button>
          {{tips}}
        </div>
      </div>
    </local-upload>
  </div>
</template>

<script>

  import uploadCommon from '../../components/LocalUpload/mixins/upload-common'
  import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
  import LocalUpload from '../../components/LocalUpload'

  export default {
    name: 'ImportForm',
    mixins: [uploadCommon],
    props: {
      value: {
        type: Number
      },
      importUrl: {
        type: Object
      },
      // 一般用于编辑，传入服务端文件数据结构
      defaultValue: {
        type: Object
      },
      tips: {
        type: String,
        default: '支持文件拓展名:xls和xlsx'
      }
    },
    data () {
      return {
        importStep: 'wait',
        format: fileIconFormat,
        humanReadableUnits: humanReadableUnits,
        currentValue: this.value,
        currentDefaultValue: this.defaultValue
      }
    },
    watch: {
      value (val) {
        this.currentValue = val
      },
      defaultValue (val) {
        this.currentDefaultValue = val
      }
    },
    methods: {
      onBeforeUpload () {
        this.uploadFileList = []
        this.importStep = 'upload'
        this.uploadStatus = true
      },
      onOneUploadComplete (body) {
        this.onImportUpload(body)
      },
      onImportUpload (file) {
        this.importStep = 'submit'
        //请求服务端接口
        this.$http(this, {
          url: this.importUrl,
          noTips: true,
          noErrorTips: true,
          data: {
            fileUrl: file.fileUrl
          },
          loading: 'uploadStatus',
          success: (data) => {
            this.$notification['success']({
              message: '导入信息成功!',
              description: data.body
            })
            this.importStep = 'wait'
            this.uploadStatus = false
          },
          error: (code, data) => {
            var errorMessage = '<div>' + data.message + '</div>'
            this.$notification['error']({
              message: '导入信息发生错误!',
              description: errorMessage
            })
            this.importStep = 'wait'
            this.uploadStatus = false
          }
        })
      }
    },
    components: { LocalUpload }
  }
</script>

<style scoped>

</style>